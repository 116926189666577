import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import Heading from "./heading"
import { Container } from "./utils"
import { ArrowRightCircle } from "react-feather"

const Banner = ({ body }) => (
  <Wrapper>
    <CustomContainer>
      <div dangerouslySetInnerHTML={{ __html: body }} />
    </CustomContainer>
  </Wrapper>
)
const Wrapper = styled.div`
  background: ${DesignTokens.colors.secondary[500]};
  color: white;
  display: block;
  text-decoration: none;
`

const CustomContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 2rem;
    margin-right: 2rem;
    margin-bottom: 1rem;
  }
  a {
    color: #fff;
  }
  p {
    font-size: 1.25rem;
    margin-bottom: 0;
  }
  svg {
    margin-left: 2rem;
  }
`

const Number = styled.div`
  background: #f55900;
  font-size: 80px;
  line-height: 90px;
  font-weight: bold;
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: "Yantramanav", -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: ${DesignTokens.colors.white};
`

const Arrow = styled.div``

export default Banner
