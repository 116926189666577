import React from "react"
import Helmet from "react-helmet"

const HomeLayout = ({ children }) => (
  <div>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins&family=Yantramanav:wght@400;900&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    {children}
  </div>
)

export default HomeLayout
