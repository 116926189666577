import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import DesignTokens from "../components/designTokens"
import { LinkButtonSecondary } from "../components/button"
import ReferenceList from "../components/referenceList"
import SEO from "../components/seo"
import CallToAction from "../components/callToAction"
import Footer from "../components/footer"
import Advantages from "../components/advantages"
import KeyNumbers from "../components/keyNumbers"
import { Container } from "../components/utils"
import Intro from "../components/intro"
import HomeLayout from "../components/homeLayout"
import HeroHeader from "../components/heroHeader"
import Banner from "../components/banner"

const Home = ({ data: { home, solutions, cta } }) => {
  return (
    <HomeLayout>
      <SEO title="Home" lang="it" />
      <HeroHeader solutions={solutions.nodes} />
      <Banner body={home.banner} />
      <Intro title="LTE Impianti" theme="dark" heading="h3" />
      <Advantages data={home} />
      <Intro title="Referenze" theme="dark" heading="h3" />
      <References references={home.references} />
      <KeyNumbers numbers={home.keyNumbers} />
      <CallToAction cta={cta} />
      <Footer />
    </HomeLayout>
  )
}

const References = ({ references }) => (
  <StyledReferences>
    <Container>
      <ReferenceList references={references} theme="dark" />
      <LinkButtonSecondary to="/referenze">
        Scopri tutte le referenze
      </LinkButtonSecondary>
    </Container>
  </StyledReferences>
)

export default Home

const StyledReferences = styled.section`
  background: ${DesignTokens.colors.primary[500]};
  color: ${DesignTokens.colors.white};
  padding: ${DesignTokens.spacing[7]} ${DesignTokens.spacing[4]};
  h3 {
    text-transform: uppercase;
    font-size: ${DesignTokens.fontSizes[8]};
    margin-bottom: ${DesignTokens.spacing[6]};
  }
`

export const query = graphql`
  query HomeQuery {
    home: datoCmsHome {
      title
      subtitle
      banner
      advantages {
        id
        description
        icon {
          alt
          path
          url
        }
        title
      }
      keyNumbers {
        id
        number
        text
      }
      references {
        client
        project
        period
        location
        id
        slug
        category {
          name
          id
        }
        sector {
          title
        }
        gallery {
          url
          alt
        }
      }
    }
    solutions: allDatoCmsSolution(sort: { fields: position }) {
      nodes {
        id
        title
        subtitle
        slug
        heroImage {
          url
        }
        color {
          blue
          green
          alpha
          red
          hex
        }
      }
    }
    cta: datoCmsCallToAction(shortName: { eq: "contact" }) {
      text
      url
      callToAction
      image {
        url
      }
    }
  }
`
