import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import { Container, ItemsList } from "./utils"

const KeyNumbers = ({ numbers }) => (
  <Wrapper>
    <StyledContainer>
      <Numbers>
        {numbers.map(number => (
          <li key={number.id}>
            <span>{number.number}</span> {number.text}
          </li>
        ))}
      </Numbers>
    </StyledContainer>
  </Wrapper>
)
const Wrapper = styled.div`
  background: ${DesignTokens.colors.primary[500]};
  @media screen and (min-width: 769px) {
    padding: 3rem 0;
  }
`

const Numbers = styled(ItemsList)`
  font-family: "Yantramanav", -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 769px) {
    flex-direction: row;
  }
  li {
    text-align: center;
    font-size: ${DesignTokens.fontSizes[8]};
    span {
      font-size: 4rem;
    }
    @media screen and (min-width: 769px) {
      width: 50%;
    }
  }
`

const StyledContainer = styled(Container)`
  color: ${DesignTokens.colors.white};
  font-weight: 900;
`

export default KeyNumbers
