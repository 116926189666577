import React from "react"
import styled from "styled-components"
import DesignTokens from "./designTokens"
import Label from "./label"
import { Container, ItemsList } from "./utils"

const Advantages = ({ data }) => (
  <Wrapper>
    <Details>
      <div>
        <h2>{data.subtitle}</h2>
      </div>
      <AdvantagesList>
        {data.advantages.map((advantage, index) => (
          <Advantage key={advantage.id}>
            <img src={advantage.icon.url} alt={advantage.icon.alt} />
            <Label>Plus 0{index + 1}</Label>
            <h4>{advantage.title}</h4>
            <p>{advantage.description}</p>
          </Advantage>
        ))}
      </AdvantagesList>
    </Details>
  </Wrapper>
)

const Wrapper = styled.section`
  background: ${DesignTokens.colors.primary[500]};
  color: ${DesignTokens.colors.white};
  h1 {
    text-transform: uppercase;
    font-size: ${DesignTokens.fontSizes[8]};
    margin-bottom: ${DesignTokens.spacing[6]};
  }
  h2 {
    font-size: ${DesignTokens.fontSizes[8]};
    line-height: 1;
    @media screen and (min-width: 769px) {
      font-size: 5rem;
    }
  }
`

const Details = styled(Container)`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 769px) {
    flex-direction: row;
    & > div {
      width: 40%;
    }
  }
`

const AdvantagesList = styled(ItemsList)`
  @media screen and (min-width: 769px) {
    display: flex;
    flex-direction: row;
    width: 60%;
    & > li {
      width: 33%;
    }
  }
`

const Advantage = styled.li`
  padding: ${DesignTokens.spacing[4]};
  margin: 0;
  h4 {
    font-weight: 400;
    font-size: ${DesignTokens.fontSizes[6]};
    margin-bottom: ${DesignTokens.spacing[4]};
  }
  img {
    margin-bottom: ${DesignTokens.spacing[5]};
  }
`

export default Advantages
