import React from "react"
import styled from "styled-components"
import Nav from "./nav"
import heroImage from "../images/hero-image.jpg"
import heroImageMobile from "../images/hero-image-mobile.jpg"
import { IsDesktop, IsMobile } from "./utils"
import LargeNav from "./largeNav"
import HeroSolutions from "./heroSolutions"

const HeroHeader = ({ solutions }) => {
  return (
    <Wrapper image={heroImage} imageMobile={heroImageMobile}>
      <IsDesktop>
        <LargeNav theme="transparent" />
      </IsDesktop>
      <IsMobile>
        <Nav theme="transparent" />
      </IsMobile>
      <HeroSolutions solutions={solutions} />
    </Wrapper>
  )
}
const Wrapper = styled.div`
  background: url(${props => props.imageMobile});
  background-size: cover;
  background-position: center;
  @media screen and (min-width: 769px) {
    background: url(${props => props.image});
    background-position: center;
    background-size: cover;
  }
`

export default HeroHeader
